import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Embed } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Account Creation with QuickBooks Online">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you use QuickBooks Online and you intend to connect QuickBooks Online to your Simple RM account, you can use a simplified account setup flow.`}</p>
    <p>{`Follow along with this video to complete the process.`}</p>
    <p>{`The flow in the video begins on the QuickBooks Online app store listing for Simple Review Management which can access by clicking `}<a parentName="p" {...{
        "href": "https://quickbooks.intuit.com/app/apps/appdetails/simplereviewmanagement/"
      }}>{`here`}</a>{`.`}</p>
    <Embed src="https://www.youtube.com/embed/bQnUqwGKE3I" mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      